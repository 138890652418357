/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

.prism-headline-mega {
    font-size: var(--prism-typography-global-type-scale-step8);
    font-family: var(--prism-typography-global-font-family-heading);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-smallest);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-20);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-mega-inlined {
    font-size: var(--prism-typography-global-type-scale-step8);
    font-family: var(--prism-typography-global-font-family-heading-inline);
    font-weight: var(--prism-typography-global-font-weight-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: var(--prism-typography-global-line-height-smallest);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-90);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-2xl {
    font-size: var(--prism-typography-global-type-scale-step7);
    font-family: var(--prism-typography-global-font-family-heading);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-smaller);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-2xl-inlined {
    font-size: var(--prism-typography-global-type-scale-step7);
    font-family: var(--prism-typography-global-font-family-heading-inline);
    font-weight: var(--prism-typography-global-font-weight-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: var(--prism-typography-global-line-height-smaller);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-100);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-xl {
    font-size: var(--prism-typography-global-type-scale-step6);
    font-family: var(--prism-typography-global-font-family-heading);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-small);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-xl-inlined {
    font-size: var(--prism-typography-global-type-scale-step6);
    font-family: var(--prism-typography-global-font-family-heading-inline);
    font-weight: var(--prism-typography-global-font-weight-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: var(--prism-typography-global-line-height-small);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-100);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-large {
    font-size: var(--prism-typography-global-type-scale-step5);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-regular);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30);
}

.prism-headline-medium {
    font-size: var(--prism-typography-global-type-scale-step4);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-headline-small {
    font-size: var(--prism-typography-global-type-scale-step3);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-20);
}

.prism-headline-xs {
    font-size: var(--prism-typography-global-type-scale-step2);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40);
}

.prism-subtitle-1 {
    font-size: var(--prism-typography-global-type-scale-step1);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-regular);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-20);
}

.prism-subtitle-2 {
    font-size: var(--prism-typography-global-type-scale-step0);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40);
}

.prism-body-1, body {
    font-size: var(--prism-typography-global-type-scale-step0);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-regular);
    line-height: var(--prism-typography-global-line-height-large);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40);
}

.prism-button, button {
    font-size: var(--prism-typography-global-type-scale-step-1);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-100);
}

.prism-caption, caption, figcaption {
    font-size: var(--prism-typography-global-type-scale-step-1);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-regular);
    line-height: var(--prism-typography-global-line-height-regular);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40);
}

.prism-overline {
    font-size: var(--prism-typography-global-type-scale-step-2);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-regular);
    line-height: var(--prism-typography-global-line-height-larger);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-120);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

.prism-microlabel {
    font-size: var(--prism-typography-global-type-scale-step-3);
    font-family: var(--prism-typography-global-font-family-default);
    font-weight: var(--prism-typography-global-font-weight-bold);
    line-height: var(--prism-typography-global-line-height-largest);
    letter-spacing: var(--prism-typography-global-letter-spacing-spacing-120);
    text-transform: uppercase; /* Should this be inherited from a global token? */
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

@font-face {
    font-family: "DIN 2014";
    src: url("assets/DIN-2014.woff2") format("woff2"),
         url("assets/DIN-2014.woff") format("woff"),
         url("assets/DIN-2014.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: bold;
    src: url("assets/DIN-2014-Bold.woff2") format("woff2"),
         url("assets/DIN-2014-Bold.woff") format("woff"),
         url("assets/DIN-2014-Bold.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: bold;
    font-style: italic;
    src: url("assets/DIN-2014-Bold-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Bold-Italic.woff") format("woff"),
         url("assets/DIN-2014-Bold-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 600;
    font-style: italic;
    src: url("assets/DIN-2014-Demi-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Demi-Italic.woff") format("woff"),
         url("assets/DIN-2014-Demi-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 600;
    src: url("assets/DIN-2014-Demi.woff2") format("woff2"),
         url("assets/DIN-2014-Demi.woff") format("woff"),
         url("assets/DIN-2014-Demi.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 800;
    font-style: italic;
    src: url("assets/DIN-2014-Extra-Bold-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Extra-Bold-Italic.woff") format("woff"),
         url("assets/DIN-2014-Extra-Bold-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 800;
    src: url("assets/DIN-2014-Extra-Bold.woff2") format("woff2"),
         url("assets/DIN-2014-Extra-Bold.woff") format("woff"),
         url("assets/DIN-2014-Extra-Bold.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 200;
    font-style: italic;
    src: url("assets/DIN-2014-Extra-Light-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Extra-Light-Italic.woff") format("woff"),
         url("assets/DIN-2014-Extra-Light-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 200;
    src: url("assets/DIN-2014-Extra-Light.woff2") format("woff2"),
         url("assets/DIN-2014-Extra-Light.woff") format("woff"),
         url("assets/DIN-2014-Extra-Light.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-style: italic;
    src: url("assets/DIN-2014-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Italic.woff") format("woff"),
         url("assets/DIN-2014-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 300;
    font-style: italic;
    src: url("assets/DIN-2014-Light-Italic.woff2") format("woff2"),
         url("assets/DIN-2014-Light-Italic.woff") format("woff"),
         url("assets/DIN-2014-Light-Italic.otf") format("opentype");
}
@font-face {
    font-family: "DIN 2014";
    font-weight: 300;
    src: url("assets/DIN-2014-Light.woff2") format("woff2"),
         url("assets/DIN-2014-Light.woff") format("woff"),
         url("assets/DIN-2014-Light.otf") format("opentype");
}

@font-face {
    font-family: "Ostrich Proper";
    src: url("assets/OstrichProper-Bold.woff2") format("woff2");
    font-weight: 700;
}
@font-face {
    font-family: "Ostrich Proper Inline";
    src: url("assets/OstrichProperInline-Open.woff2") format("woff2");
    font-weight: 400;
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

:root {
  --prism-typography-global-type-scale-step8: clamp(4.2998rem, 3.7222rem + 2.8881vw, 5.9605rem);
  --prism-typography-global-type-scale-step7: clamp(3.5832rem, 3.1709rem + 2.0612vw, 4.7684rem);
  --prism-typography-global-type-scale-step6: clamp(2.9860rem, 2.6977rem + 1.4412vw, 3.8147rem);
  --prism-typography-global-type-scale-step5: clamp(2.4883rem, 2.2923rem + 0.9799vw, 3.0518rem);
  --prism-typography-global-type-scale-step4: clamp(2.0736rem, 1.9457rem + 0.6397vw, 2.4414rem);
  --prism-typography-global-type-scale-step3: clamp(1.7280rem, 1.6497rem + 0.3915vw, 1.9531rem);
  --prism-typography-global-type-scale-step2: clamp(1.4400rem, 1.3974rem + 0.2130vw, 1.5625rem);
  --prism-typography-global-type-scale-step1: clamp(1.2000rem, 1.1826rem + 0.0870vw, 1.2500rem);
  --prism-typography-global-type-scale-step0: clamp(1.0000rem, 1.0000rem + 0.0000vw, 1.0000rem);
  --prism-typography-global-type-scale-step-1: clamp(0.8333rem, 0.8449rem + -0.0580vw, 0.8000rem);
  --prism-typography-global-type-scale-step-2: clamp(0.6944rem, 0.7134rem + -0.0947vw, 0.6400rem);
  --prism-typography-global-type-scale-step-3: clamp(0.5787rem, 0.6019rem + -0.1160vw, 0.5120rem);
  --prism-typography-global-letter-spacing-spacing-120: 0.12em;
  --prism-typography-global-letter-spacing-spacing-100: 0.1em;
  --prism-typography-global-letter-spacing-spacing-90: 0.09em;
  --prism-typography-global-letter-spacing-spacing-80: 0.08em;
  --prism-typography-global-letter-spacing-spacing-40: 0.04em;
  --prism-typography-global-letter-spacing-spacing-30: 0.03em;
  --prism-typography-global-letter-spacing-spacing-20: 0.02em;
  --prism-typography-global-letter-spacing-spacing-normal: normal;
  --prism-typography-global-line-height-largest: 1.75;
  --prism-typography-global-line-height-larger: 1.6;
  --prism-typography-global-line-height-large: 1.4;
  --prism-typography-global-line-height-regular: 1.3;
  --prism-typography-global-line-height-small: 1.2;
  --prism-typography-global-line-height-smaller: 1.1;
  --prism-typography-global-line-height-smallest: 1;
  --prism-typography-global-font-weight-heavy: 900; /* Not in DIN 2014 */
  --prism-typography-global-font-weight-extra-bold: 800;
  --prism-typography-global-font-weight-bold: 700;
  --prism-typography-global-font-weight-demi: 600;
  --prism-typography-global-font-weight-medium: 500; /* Not in DIN 2014 */
  --prism-typography-global-font-weight-regular: 400;
  --prism-typography-global-font-weight-light: 300;
  --prism-typography-global-font-weight-extra-light: 200;
  --prism-typography-global-font-weight-hairline: 100;
  --prism-typography-global-font-family-heading-inline: 'Ostrich Proper Inline', sans-serif;
  --prism-typography-global-font-family-heading: 'Ostrich Proper', sans-serif;
  --prism-typography-global-font-family-default: 'DIN 2014', Roboto, sans-serif;
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

:root {
  --prism-border-radius-global-normal: 8px;
  --prism-border-radius-global-pill: calc(infinity * 1px);
  --prism-border-radius-global-circle: 50%;
  --prism-color-global-black: #000000;
  --prism-color-global-black-rgb: 0, 0, 0;
  --prism-color-global-white: #ffffff;
  --prism-color-global-white-rgb: 255, 255, 255;
  --prism-color-global-cyan: #009bdf;
  --prism-color-global-cyan-rgb: 0, 155, 223;
  --prism-color-global-gray-t-80: #fbfbfc;
  --prism-color-global-gray-t-80-rgb: 251, 251, 252;
  --prism-color-global-gray-t-70: #f7f7f8;
  --prism-color-global-gray-t-70-rgb: 247, 247, 248;
  --prism-color-global-gray-t-60: #f2f2f4;
  --prism-color-global-gray-t-60-rgb: 242, 242, 244;
  --prism-color-global-gray-t-50: #ececed;
  --prism-color-global-gray-t-50-rgb: 236, 236, 237;
  --prism-color-global-gray-t-40: #e7e7e9;
  --prism-color-global-gray-t-40-rgb: 231, 231, 233;
  --prism-color-global-gray-t-30: #d7d7d9;
  --prism-color-global-gray-t-30-rgb: 215, 215, 217;
  --prism-color-global-gray-t-20: #c6c6c9;
  --prism-color-global-gray-t-20-rgb: 198, 198, 201;
  --prism-color-global-gray-t-10: #c4c4c6;
  --prism-color-global-gray-t-10-rgb: 196, 196, 198;
  --prism-color-global-gray: #b2b2b4;
  --prism-color-global-gray-rgb: 178, 178, 180;
  --prism-color-global-gray-s-10: #949496;
  --prism-color-global-gray-s-10-rgb: 148, 148, 150;
  --prism-color-global-gray-s-20: #8c8c8e;
  --prism-color-global-gray-s-20-rgb: 140, 140, 142;
  --prism-color-global-gray-s-30: #626264;
  --prism-color-global-gray-s-30-rgb: 98, 98, 100;
  --prism-color-global-gray-s-40: #404041;
  --prism-color-global-gray-s-40-rgb: 64, 64, 65;
  --prism-color-global-gray-s-50: #333335;
  --prism-color-global-gray-s-50-rgb: 51, 51, 53;
  --prism-color-global-gray-s-60: #222222;
  --prism-color-global-gray-s-60-rgb: 34, 34, 34;
  --prism-color-global-gray-s-70: #161616;
  --prism-color-global-gray-s-70-rgb: 22, 22, 22;
  --prism-color-global-blue-t-40: #c4fbff;
  --prism-color-global-blue-t-40-rgb: 196, 251, 255;
  --prism-color-global-blue-t-30: #9ef5ff;
  --prism-color-global-blue-t-30-rgb: 158, 245, 255;
  --prism-color-global-blue-t-20: #7aedff;
  --prism-color-global-blue-t-20-rgb: 122, 237, 255;
  --prism-color-global-blue-t-10: #52e2ff;
  --prism-color-global-blue-t-10-rgb: 82, 226, 255;
  --prism-color-global-blue: #41d3f8;
  --prism-color-global-blue-rgb: 65, 211, 248;
  --prism-color-global-blue-s-10: #27bcf2;
  --prism-color-global-blue-s-10-rgb: 39, 188, 242;
  --prism-color-global-blue-s-20: #11a1e1;
  --prism-color-global-blue-s-20-rgb: 17, 161, 225;
  --prism-color-global-blue-s-30: #0d93d6;
  --prism-color-global-blue-s-30-rgb: 13, 147, 214;
  --prism-color-global-blue-s-40: #017bc3;
  --prism-color-global-blue-s-40-rgb: 1, 123, 195;
  --prism-color-global-blue-s-50: #0061a0;
  --prism-color-global-blue-s-50-rgb: 0, 97, 160;
  --prism-color-global-ignition-green-t-10: #d3ff94;
  --prism-color-global-ignition-green-t-10-rgb: 211, 255, 148;
  --prism-color-global-ignition-green: #b2ff33;
  --prism-color-global-ignition-green-rgb: 178, 255, 51;
  --prism-color-global-ignition-green-s-10: #9aea00;
  --prism-color-global-ignition-green-s-10-rgb: 154, 234, 0;
  --prism-color-global-green: #00c853;
  --prism-color-global-green-rgb: 0, 200, 83;
  --prism-color-global-green-s-10: #319e01;
  --prism-color-global-green-s-10-rgb: 49, 158, 1;
  --prism-color-global-yellow: #ffea00;
  --prism-color-global-yellow-rgb: 255, 234, 0;
  --prism-color-global-yellow-s-10: #ffd200;
  --prism-color-global-yellow-s-10-rgb: 255, 210, 0;
  --prism-color-global-orange: #ffaa00;
  --prism-color-global-orange-rgb: 255, 170, 0;
  --prism-color-global-orange-s-10: #ff6d00;
  --prism-color-global-orange-s-10-rgb: 255, 109, 0;
  --prism-color-global-pink: #eb008b;
  --prism-color-global-pink-rgb: 235, 0, 139;
  --prism-color-global-purple: #9c55c8;
  --prism-color-global-purple-rgb: 156, 85, 200;
  --prism-color-global-red: #ff5252;
  --prism-color-global-red-rgb: 255, 82, 82;
  --prism-color-global-red-s-10: #f44336;
  --prism-color-global-red-s-10-rgb: 244, 67, 54;
  --prism-color-global-red-s-50: #d6001c;
  --prism-color-global-red-s-50-rgb: 214, 0, 28;
  --prism-opacity-global-6: .06;
  --prism-opacity-global-12: .12;
  --prism-opacity-global-24: .24;
  --prism-opacity-global-30: .30;
  --prism-opacity-global-40: .40;
  --prism-opacity-global-50: .50;
  --prism-opacity-global-60: .60;
  --prism-opacity-global-70: .70;
  --prism-opacity-global-87: .87;
  --prism-spacing-global-step-1: 2px;
  --prism-spacing-global-step-2: 4px;
  --prism-spacing-global-step-3: 6px;
  --prism-spacing-global-step-4: 8px;
  --prism-spacing-global-step-5: 12px;
  --prism-spacing-global-step-6: 16px;
  --prism-spacing-global-step-7: 20px;
  --prism-spacing-global-step-8: 24px;
  --prism-spacing-global-step-9: 28px;
  --prism-spacing-global-step-10: 32px;
  --prism-spacing-global-step-11: 36px;
  --prism-spacing-global-step-12: 40px;
  --prism-spacing-global-step-13: 48px;
  --prism-spacing-global-step-1-rem: 0.125rem;
  --prism-spacing-global-step-2-rem: 0.25rem;
  --prism-spacing-global-step-3-rem: 0.375rem;
  --prism-spacing-global-step-4-rem: 0.5rem;
  --prism-spacing-global-step-5-rem: 0.75rem;
  --prism-spacing-global-step-6-rem: 1rem;
  --prism-spacing-global-step-7-rem: 1.25rem;
  --prism-spacing-global-step-8-rem: 1.5rem;
  --prism-spacing-global-step-9-rem: 1.75rem;
  --prism-spacing-global-step-10-rem: 2rem;
  --prism-spacing-global-step-11-rem: 2.25rem;
  --prism-spacing-global-step-12-rem: 2.5rem;
  --prism-spacing-global-step-13-rem: 3rem;
  --prism-spacing-global-step-1-step-2: clamp(2px, 1.3333px + 0.2083vw, 4px);
  --prism-spacing-global-step-1-step-2-rem: clamp(0.125rem, 0.0833rem + 0.2083vw, 0.25rem);
  --prism-spacing-global-step-1-step-3: clamp(2px, 0.6667px + 0.4167vw, 6px);
  --prism-spacing-global-step-1-step-3-rem: clamp(0.125rem, 0.0417rem + 0.4167vw, 0.375rem);
  --prism-spacing-global-step-1-step-4: clamp(2px, 0.0000px + 0.6250vw, 8px);
  --prism-spacing-global-step-1-step-4-rem: clamp(0.125rem, 0.0000rem + 0.6250vw, 0.5rem);
  --prism-spacing-global-step-1-step-5: clamp(2px, -1.3333px + 1.0417vw, 12px);
  --prism-spacing-global-step-1-step-5-rem: clamp(0.125rem, -0.0833rem + 1.0417vw, 0.75rem);
  --prism-spacing-global-step-1-step-6: clamp(2px, -2.6667px + 1.4583vw, 16px);
  --prism-spacing-global-step-1-step-6-rem: clamp(0.125rem, -0.1667rem + 1.4583vw, 1rem);
  --prism-spacing-global-step-1-step-7: clamp(2px, -4.0000px + 1.8750vw, 20px);
  --prism-spacing-global-step-1-step-7-rem: clamp(0.125rem, -0.2500rem + 1.8750vw, 1.25rem);
  --prism-spacing-global-step-1-step-8: clamp(2px, -5.3333px + 2.2917vw, 24px);
  --prism-spacing-global-step-1-step-8-rem: clamp(0.125rem, -0.3333rem + 2.2917vw, 1.5rem);
  --prism-spacing-global-step-1-step-9: clamp(2px, -6.6667px + 2.7083vw, 28px);
  --prism-spacing-global-step-1-step-9-rem: clamp(0.125rem, -0.4167rem + 2.7083vw, 1.75rem);
  --prism-spacing-global-step-1-step-10: clamp(2px, -8.0000px + 3.1250vw, 32px);
  --prism-spacing-global-step-1-step-10-rem: clamp(0.125rem, -0.5000rem + 3.1250vw, 2rem);
  --prism-spacing-global-step-1-step-11: clamp(2px, -9.3333px + 3.5417vw, 36px);
  --prism-spacing-global-step-1-step-11-rem: clamp(0.125rem, -0.5833rem + 3.5417vw, 2.25rem);
  --prism-spacing-global-step-1-step-12: clamp(2px, -10.6667px + 3.9583vw, 40px);
  --prism-spacing-global-step-1-step-12-rem: clamp(0.125rem, -0.6667rem + 3.9583vw, 2.5rem);
  --prism-spacing-global-step-1-step-13: clamp(2px, -13.3333px + 4.7917vw, 48px);
  --prism-spacing-global-step-1-step-13-rem: clamp(0.125rem, -0.8333rem + 4.7917vw, 3rem);
  --prism-spacing-global-step-2-step-3: clamp(4px, 3.3333px + 0.2083vw, 6px);
  --prism-spacing-global-step-2-step-3-rem: clamp(0.25rem, 0.2083rem + 0.2083vw, 0.375rem);
  --prism-spacing-global-step-2-step-4: clamp(4px, 2.6667px + 0.4167vw, 8px);
  --prism-spacing-global-step-2-step-4-rem: clamp(0.25rem, 0.1667rem + 0.4167vw, 0.5rem);
  --prism-spacing-global-step-2-step-5: clamp(4px, 1.3333px + 0.8333vw, 12px);
  --prism-spacing-global-step-2-step-5-rem: clamp(0.25rem, 0.0833rem + 0.8333vw, 0.75rem);
  --prism-spacing-global-step-2-step-6: clamp(4px, 0.0000px + 1.2500vw, 16px);
  --prism-spacing-global-step-2-step-6-rem: clamp(0.25rem, 0.0000rem + 1.2500vw, 1rem);
  --prism-spacing-global-step-2-step-7: clamp(4px, -1.3333px + 1.6667vw, 20px);
  --prism-spacing-global-step-2-step-7-rem: clamp(0.25rem, -0.0833rem + 1.6667vw, 1.25rem);
  --prism-spacing-global-step-2-step-8: clamp(4px, -2.6667px + 2.0833vw, 24px);
  --prism-spacing-global-step-2-step-8-rem: clamp(0.25rem, -0.1667rem + 2.0833vw, 1.5rem);
  --prism-spacing-global-step-2-step-9: clamp(4px, -4.0000px + 2.5000vw, 28px);
  --prism-spacing-global-step-2-step-9-rem: clamp(0.25rem, -0.2500rem + 2.5000vw, 1.75rem);
  --prism-spacing-global-step-2-step-10: clamp(4px, -5.3333px + 2.9167vw, 32px);
  --prism-spacing-global-step-2-step-10-rem: clamp(0.25rem, -0.3333rem + 2.9167vw, 2rem);
  --prism-spacing-global-step-2-step-11: clamp(4px, -6.6667px + 3.3333vw, 36px);
  --prism-spacing-global-step-2-step-11-rem: clamp(0.25rem, -0.4167rem + 3.3333vw, 2.25rem);
  --prism-spacing-global-step-2-step-12: clamp(4px, -8.0000px + 3.7500vw, 40px);
  --prism-spacing-global-step-2-step-12-rem: clamp(0.25rem, -0.5000rem + 3.7500vw, 2.5rem);
  --prism-spacing-global-step-2-step-13: clamp(4px, -10.6667px + 4.5833vw, 48px);
  --prism-spacing-global-step-2-step-13-rem: clamp(0.25rem, -0.6667rem + 4.5833vw, 3rem);
  --prism-spacing-global-step-3-step-4: clamp(6px, 5.3333px + 0.2083vw, 8px);
  --prism-spacing-global-step-3-step-4-rem: clamp(0.375rem, 0.3333rem + 0.2083vw, 0.5rem);
  --prism-spacing-global-step-3-step-5: clamp(6px, 4.0000px + 0.6250vw, 12px);
  --prism-spacing-global-step-3-step-5-rem: clamp(0.375rem, 0.2500rem + 0.6250vw, 0.75rem);
  --prism-spacing-global-step-3-step-6: clamp(6px, 2.6667px + 1.0417vw, 16px);
  --prism-spacing-global-step-3-step-6-rem: clamp(0.375rem, 0.1667rem + 1.0417vw, 1rem);
  --prism-spacing-global-step-3-step-7: clamp(6px, 1.3333px + 1.4583vw, 20px);
  --prism-spacing-global-step-3-step-7-rem: clamp(0.375rem, 0.0833rem + 1.4583vw, 1.25rem);
  --prism-spacing-global-step-3-step-8: clamp(6px, 0.0000px + 1.8750vw, 24px);
  --prism-spacing-global-step-3-step-8-rem: clamp(0.375rem, 0.0000rem + 1.8750vw, 1.5rem);
  --prism-spacing-global-step-3-step-9: clamp(6px, -1.3333px + 2.2917vw, 28px);
  --prism-spacing-global-step-3-step-9-rem: clamp(0.375rem, -0.0833rem + 2.2917vw, 1.75rem);
  --prism-spacing-global-step-3-step-10: clamp(6px, -2.6667px + 2.7083vw, 32px);
  --prism-spacing-global-step-3-step-10-rem: clamp(0.375rem, -0.1667rem + 2.7083vw, 2rem);
  --prism-spacing-global-step-3-step-11: clamp(6px, -4.0000px + 3.1250vw, 36px);
  --prism-spacing-global-step-3-step-11-rem: clamp(0.375rem, -0.2500rem + 3.1250vw, 2.25rem);
  --prism-spacing-global-step-3-step-12: clamp(6px, -5.3333px + 3.5417vw, 40px);
  --prism-spacing-global-step-3-step-12-rem: clamp(0.375rem, -0.3333rem + 3.5417vw, 2.5rem);
  --prism-spacing-global-step-3-step-13: clamp(6px, -8.0000px + 4.3750vw, 48px);
  --prism-spacing-global-step-3-step-13-rem: clamp(0.375rem, -0.5000rem + 4.3750vw, 3rem);
  --prism-spacing-global-step-4-step-5: clamp(8px, 6.6667px + 0.4167vw, 12px);
  --prism-spacing-global-step-4-step-5-rem: clamp(0.5rem, 0.4167rem + 0.4167vw, 0.75rem);
  --prism-spacing-global-step-4-step-6: clamp(8px, 5.3333px + 0.8333vw, 16px);
  --prism-spacing-global-step-4-step-6-rem: clamp(0.5rem, 0.3333rem + 0.8333vw, 1rem);
  --prism-spacing-global-step-4-step-7: clamp(8px, 4.0000px + 1.2500vw, 20px);
  --prism-spacing-global-step-4-step-7-rem: clamp(0.5rem, 0.2500rem + 1.2500vw, 1.25rem);
  --prism-spacing-global-step-4-step-8: clamp(8px, 2.6667px + 1.6667vw, 24px);
  --prism-spacing-global-step-4-step-8-rem: clamp(0.5rem, 0.1667rem + 1.6667vw, 1.5rem);
  --prism-spacing-global-step-4-step-9: clamp(8px, 1.3333px + 2.0833vw, 28px);
  --prism-spacing-global-step-4-step-9-rem: clamp(0.5rem, 0.0833rem + 2.0833vw, 1.75rem);
  --prism-spacing-global-step-4-step-10: clamp(8px, 0.0000px + 2.5000vw, 32px);
  --prism-spacing-global-step-4-step-10-rem: clamp(0.5rem, 0.0000rem + 2.5000vw, 2rem);
  --prism-spacing-global-step-4-step-11: clamp(8px, -1.3333px + 2.9167vw, 36px);
  --prism-spacing-global-step-4-step-11-rem: clamp(0.5rem, -0.0833rem + 2.9167vw, 2.25rem);
  --prism-spacing-global-step-4-step-12: clamp(8px, -2.6667px + 3.3333vw, 40px);
  --prism-spacing-global-step-4-step-12-rem: clamp(0.5rem, -0.1667rem + 3.3333vw, 2.5rem);
  --prism-spacing-global-step-4-step-13: clamp(8px, -5.3333px + 4.1667vw, 48px);
  --prism-spacing-global-step-4-step-13-rem: clamp(0.5rem, -0.3333rem + 4.1667vw, 3rem);
  --prism-spacing-global-step-5-step-6: clamp(12px, 10.6667px + 0.4167vw, 16px);
  --prism-spacing-global-step-5-step-6-rem: clamp(0.75rem, 0.6667rem + 0.4167vw, 1rem);
  --prism-spacing-global-step-5-step-7: clamp(12px, 9.3333px + 0.8333vw, 20px);
  --prism-spacing-global-step-5-step-7-rem: clamp(0.75rem, 0.5833rem + 0.8333vw, 1.25rem);
  --prism-spacing-global-step-5-step-8: clamp(12px, 8.0000px + 1.2500vw, 24px);
  --prism-spacing-global-step-5-step-8-rem: clamp(0.75rem, 0.5000rem + 1.2500vw, 1.5rem);
  --prism-spacing-global-step-5-step-9: clamp(12px, 6.6667px + 1.6667vw, 28px);
  --prism-spacing-global-step-5-step-9-rem: clamp(0.75rem, 0.4167rem + 1.6667vw, 1.75rem);
  --prism-spacing-global-step-5-step-10: clamp(12px, 5.3333px + 2.0833vw, 32px);
  --prism-spacing-global-step-5-step-10-rem: clamp(0.75rem, 0.3333rem + 2.0833vw, 2rem);
  --prism-spacing-global-step-5-step-11: clamp(12px, 4.0000px + 2.5000vw, 36px);
  --prism-spacing-global-step-5-step-11-rem: clamp(0.75rem, 0.2500rem + 2.5000vw, 2.25rem);
  --prism-spacing-global-step-5-step-12: clamp(12px, 2.6667px + 2.9167vw, 40px);
  --prism-spacing-global-step-5-step-12-rem: clamp(0.75rem, 0.1667rem + 2.9167vw, 2.5rem);
  --prism-spacing-global-step-5-step-13: clamp(12px, 0.0000px + 3.7500vw, 48px);
  --prism-spacing-global-step-5-step-13-rem: clamp(0.75rem, 0.0000rem + 3.7500vw, 3rem);
  --prism-spacing-global-step-6-step-7: clamp(16px, 14.6667px + 0.4167vw, 20px);
  --prism-spacing-global-step-6-step-7-rem: clamp(1rem, 0.9167rem + 0.4167vw, 1.25rem);
  --prism-spacing-global-step-6-step-8: clamp(16px, 13.3333px + 0.8333vw, 24px);
  --prism-spacing-global-step-6-step-8-rem: clamp(1rem, 0.8333rem + 0.8333vw, 1.5rem);
  --prism-spacing-global-step-6-step-9: clamp(16px, 12.0000px + 1.2500vw, 28px);
  --prism-spacing-global-step-6-step-9-rem: clamp(1rem, 0.7500rem + 1.2500vw, 1.75rem);
  --prism-spacing-global-step-6-step-10: clamp(16px, 10.6667px + 1.6667vw, 32px);
  --prism-spacing-global-step-6-step-10-rem: clamp(1rem, 0.6667rem + 1.6667vw, 2rem);
  --prism-spacing-global-step-6-step-11: clamp(16px, 9.3333px + 2.0833vw, 36px);
  --prism-spacing-global-step-6-step-11-rem: clamp(1rem, 0.5833rem + 2.0833vw, 2.25rem);
  --prism-spacing-global-step-6-step-12: clamp(16px, 8.0000px + 2.5000vw, 40px);
  --prism-spacing-global-step-6-step-12-rem: clamp(1rem, 0.5000rem + 2.5000vw, 2.5rem);
  --prism-spacing-global-step-6-step-13: clamp(16px, 5.3333px + 3.3333vw, 48px);
  --prism-spacing-global-step-6-step-13-rem: clamp(1rem, 0.3333rem + 3.3333vw, 3rem);
  --prism-spacing-global-step-7-step-8: clamp(20px, 18.6667px + 0.4167vw, 24px);
  --prism-spacing-global-step-7-step-8-rem: clamp(1.25rem, 1.1667rem + 0.4167vw, 1.5rem);
  --prism-spacing-global-step-7-step-9: clamp(20px, 17.3333px + 0.8333vw, 28px);
  --prism-spacing-global-step-7-step-9-rem: clamp(1.25rem, 1.0833rem + 0.8333vw, 1.75rem);
  --prism-spacing-global-step-7-step-10: clamp(20px, 16.0000px + 1.2500vw, 32px);
  --prism-spacing-global-step-7-step-10-rem: clamp(1.25rem, 1.0000rem + 1.2500vw, 2rem);
  --prism-spacing-global-step-7-step-11: clamp(20px, 14.6667px + 1.6667vw, 36px);
  --prism-spacing-global-step-7-step-11-rem: clamp(1.25rem, 0.9167rem + 1.6667vw, 2.25rem);
  --prism-spacing-global-step-7-step-12: clamp(20px, 13.3333px + 2.0833vw, 40px);
  --prism-spacing-global-step-7-step-12-rem: clamp(1.25rem, 0.8333rem + 2.0833vw, 2.5rem);
  --prism-spacing-global-step-7-step-13: clamp(20px, 10.6667px + 2.9167vw, 48px);
  --prism-spacing-global-step-7-step-13-rem: clamp(1.25rem, 0.6667rem + 2.9167vw, 3rem);
  --prism-spacing-global-step-8-step-9: clamp(24px, 22.6667px + 0.4167vw, 28px);
  --prism-spacing-global-step-8-step-9-rem: clamp(1.5rem, 1.4167rem + 0.4167vw, 1.75rem);
  --prism-spacing-global-step-8-step-10: clamp(24px, 21.3333px + 0.8333vw, 32px);
  --prism-spacing-global-step-8-step-10-rem: clamp(1.5rem, 1.3333rem + 0.8333vw, 2rem);
  --prism-spacing-global-step-8-step-11: clamp(24px, 20.0000px + 1.2500vw, 36px);
  --prism-spacing-global-step-8-step-11-rem: clamp(1.5rem, 1.2500rem + 1.2500vw, 2.25rem);
  --prism-spacing-global-step-8-step-12: clamp(24px, 18.6667px + 1.6667vw, 40px);
  --prism-spacing-global-step-8-step-12-rem: clamp(1.5rem, 1.1667rem + 1.6667vw, 2.5rem);
  --prism-spacing-global-step-8-step-13: clamp(24px, 16.0000px + 2.5000vw, 48px);
  --prism-spacing-global-step-8-step-13-rem: clamp(1.5rem, 1.0000rem + 2.5000vw, 3rem);
  --prism-spacing-global-step-9-step-10: clamp(28px, 26.6667px + 0.4167vw, 32px);
  --prism-spacing-global-step-9-step-10-rem: clamp(1.75rem, 1.6667rem + 0.4167vw, 2rem);
  --prism-spacing-global-step-9-step-11: clamp(28px, 25.3333px + 0.8333vw, 36px);
  --prism-spacing-global-step-9-step-11-rem: clamp(1.75rem, 1.5833rem + 0.8333vw, 2.25rem);
  --prism-spacing-global-step-9-step-12: clamp(28px, 24.0000px + 1.2500vw, 40px);
  --prism-spacing-global-step-9-step-12-rem: clamp(1.75rem, 1.5000rem + 1.2500vw, 2.5rem);
  --prism-spacing-global-step-9-step-13: clamp(28px, 21.3333px + 2.0833vw, 48px);
  --prism-spacing-global-step-9-step-13-rem: clamp(1.75rem, 1.3333rem + 2.0833vw, 3rem);
  --prism-spacing-global-step-10-step-11: clamp(32px, 30.6667px + 0.4167vw, 36px);
  --prism-spacing-global-step-10-step-11-rem: clamp(2rem, 1.9167rem + 0.4167vw, 2.25rem);
  --prism-spacing-global-step-10-step-12: clamp(32px, 29.3333px + 0.8333vw, 40px);
  --prism-spacing-global-step-10-step-12-rem: clamp(2rem, 1.8333rem + 0.8333vw, 2.5rem);
  --prism-spacing-global-step-10-step-13: clamp(32px, 26.6667px + 1.6667vw, 48px);
  --prism-spacing-global-step-10-step-13-rem: clamp(2rem, 1.6667rem + 1.6667vw, 3rem);
  --prism-spacing-global-step-11-step-12: clamp(36px, 34.6667px + 0.4167vw, 40px);
  --prism-spacing-global-step-11-step-12-rem: clamp(2.25rem, 2.1667rem + 0.4167vw, 2.5rem);
  --prism-spacing-global-step-11-step-13: clamp(36px, 32.0000px + 1.2500vw, 48px);
  --prism-spacing-global-step-11-step-13-rem: clamp(2.25rem, 2.0000rem + 1.2500vw, 3rem);
  --prism-spacing-global-step-12-step-13: clamp(40px, 37.3333px + 0.8333vw, 48px);
  --prism-spacing-global-step-12-step-13-rem: clamp(2.5rem, 2.3333rem + 0.8333vw, 3rem);
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

:where(:root),
[theme='light'] {
  --prism-shadow-theme-swap-xl: 0px 45px 81px 0px rgb(0 0 0 / .3), 0px 9px 13.16px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-swap-large: 0px 22px 57px 0px rgb(0 0 0 / .3), 0px 4.4px 9.26px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-swap-medium: 0px 12px 22px 0px rgb(0 0 0 / .3), 0px 2.4px 3.58px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-swap-small: 0px 5px 14px 0px rgb(0 0 0 / .3), 0px 1px 2.28px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-swap-xs: 0px 2px 5px 0px rgb(0 0 0 / .3), 0px 0.4px 0.81px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-xl: 0px 45px 81px 0px rgb(0 0 0 / .2), 0px 9px 13.16px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-large: 0px 22px 57px 0px rgb(0 0 0 / .2), 0px 4.4px 9.26px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-medium: 0px 12px 22px 0px rgb(0 0 0 / .2), 0px 2.4px 3.58px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-small: 0px 5px 14px 0px rgb(0 0 0 / .2), 0px 1px 2.28px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-xs: 0px 2px 5px 0px rgb(0 0 0 / .2), 0px 0.4px 0.81px 0px rgb(0 0 0 / .1);
  --prism-color-theme-swap-window-film-accent-primary: rgba(var(--prism-color-global-ignition-green-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-swap-window-film-disabled: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-50));
  --prism-color-theme-swap-window-film-focused: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-30));
  --prism-color-theme-swap-window-film-pressed: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-swap-window-film-hover: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-window-film-pop: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-brand-pink: var(--prism-color-global-pink);
  --prism-color-theme-swap-brand-purple: var(--prism-color-global-purple);
  --prism-color-theme-swap-brand-cyan: var(--prism-color-global-cyan);
  --prism-color-theme-swap-brand-blue: var(--prism-color-global-blue-s-50);
  --prism-color-theme-swap-brand-green: var(--prism-color-global-green-s-10);
  --prism-color-theme-swap-brand-yellow: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-swap-brand-orange: var(--prism-color-global-orange);
  --prism-color-theme-swap-brand-red: var(--prism-color-global-red-s-50);
  --prism-color-theme-swap-brand-ignition-green: var(--prism-color-global-ignition-green);
  --prism-color-theme-swap-state-offline: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-state-charging-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-state-charging: var(--prism-color-global-orange-s-10);
  --prism-color-theme-swap-state-muted-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-swap-state-muted: var(--prism-color-global-red);
  --prism-color-theme-swap-state-bad-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-swap-state-bad: var(--prism-color-global-red);
  --prism-color-theme-swap-state-ok-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-state-ok: var(--prism-color-global-yellow);
  --prism-color-theme-swap-state-good-active-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-state-good-active: var(--prism-color-global-green);
  --prism-color-theme-swap-disabled-contrast: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-40));
  --prism-color-theme-swap-disabled: var(--prism-color-global-gray-s-30);
  --prism-color-theme-swap-accent-primary-contrast: var(--prism-color-global-black);
  --prism-color-theme-swap-accent-primary: var(--prism-color-global-ignition-green);
  --prism-color-theme-swap-surface-variant: var(--prism-color-global-gray-s-50);
  --prism-color-theme-swap-surface: var(--prism-color-global-gray-s-40);
  --prism-color-theme-swap-neutral-pop-highest: var(--prism-color-global-gray-t-60);
  --prism-color-theme-swap-neutral-pop-higher: var(--prism-color-global-gray-t-30);
  --prism-color-theme-swap-neutral-pop-high: var(--prism-color-global-gray);
  --prism-color-theme-swap-neutral-pop: var(--prism-color-global-gray-s-20);
  --prism-color-theme-swap-neutral: var(--prism-color-global-gray-s-40);
  --prism-color-theme-swap-neutral-dim: var(--prism-color-global-gray-s-50);
  --prism-color-theme-swap-spacer: var(--prism-color-global-gray-t-40);
  --prism-color-theme-swap-outline: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-ink: var(--prism-color-global-white);
  --prism-color-theme-swap-base-contrast: var(--prism-color-global-white);
  --prism-color-theme-swap-base-variant: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-base: var(--prism-color-global-black);
  --prism-color-theme-window-film-accent-primary: rgba(var(--prism-color-global-blue-s-50-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-window-film-disabled: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-50));
  --prism-color-theme-window-film-focused: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-window-film-pressed: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-window-film-hover: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-6));
  --prism-color-theme-window-film-pop: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-6));
  --prism-color-theme-brand-pink: var(--prism-color-global-pink);
  --prism-color-theme-brand-purple: var(--prism-color-global-purple);
  --prism-color-theme-brand-cyan: var(--prism-color-global-cyan);
  --prism-color-theme-brand-blue: var(--prism-color-global-blue-s-50);
  --prism-color-theme-brand-green: var(--prism-color-global-green-s-10);
  --prism-color-theme-brand-yellow: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-brand-orange: var(--prism-color-global-orange);
  --prism-color-theme-brand-red: var(--prism-color-global-red-s-50);
  --prism-color-theme-brand-ignition-green: var(--prism-color-global-ignition-green);
  --prism-color-theme-state-offline: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-state-charging-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-state-charging: var(--prism-color-global-orange);
  --prism-color-theme-state-muted-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-state-muted: var(--prism-color-global-red);
  --prism-color-theme-state-bad-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-state-bad: var(--prism-color-global-red-s-10);
  --prism-color-theme-state-ok-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-state-ok: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-state-good-active-contrast: var(--prism-color-global-white);
  --prism-color-theme-state-good-active: var(--prism-color-global-green-s-10);
  --prism-color-theme-disabled-contrast: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-40));
  --prism-color-theme-disabled: var(--prism-color-global-gray-s-20);
  --prism-color-theme-accent-primary-contrast: var(--prism-color-global-white);
  --prism-color-theme-accent-primary: var(--prism-color-global-blue-s-50);
  --prism-color-theme-surface-variant: var(--prism-color-global-white);
  --prism-color-theme-surface: var(--prism-color-global-gray-t-60);
  --prism-color-theme-neutral-pop-highest: var(--prism-color-global-gray-s-50);
  --prism-color-theme-neutral-pop-higher: var(--prism-color-global-gray-s-20);
  --prism-color-theme-neutral-pop-high: var(--prism-color-global-gray);
  --prism-color-theme-neutral-pop: var(--prism-color-global-gray-t-30);
  --prism-color-theme-neutral: var(--prism-color-global-gray-t-40);
  --prism-color-theme-neutral-dim: var(--prism-color-global-gray-t-60);
  --prism-color-theme-spacer: var(--prism-color-global-gray-s-40);
  --prism-color-theme-outline: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-ink: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-87));
  --prism-color-theme-base-contrast: var(--prism-color-global-black);
  --prism-color-theme-base-variant: var(--prism-color-global-gray-t-40);
  --prism-color-theme-base: var(--prism-color-global-white);
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

[theme='dark'] {
  --prism-shadow-theme-xl: 0px 45px 81px 0px rgb(0 0 0 / .3), 0px 9px 13.16px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-large: 0px 22px 57px 0px rgb(0 0 0 / .3), 0px 4.4px 9.26px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-medium: 0px 12px 22px 0px rgb(0 0 0 / .3), 0px 2.4px 3.58px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-small: 0px 5px 14px 0px rgb(0 0 0 / .3), 0px 1px 2.28px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-xs: 0px 2px 5px 0px rgb(0 0 0 / .3), 0px 0.4px 0.81px 0px rgb(0 0 0 / .2);
  --prism-shadow-theme-swap-xl: 0px 45px 81px 0px rgb(0 0 0 / .2), 0px 9px 13.16px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-swap-large: 0px 22px 57px 0px rgb(0 0 0 / .2), 0px 4.4px 9.26px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-swap-medium: 0px 12px 22px 0px rgb(0 0 0 / .2), 0px 2.4px 3.58px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-swap-small: 0px 5px 14px 0px rgb(0 0 0 / .2), 0px 1px 2.28px 0px rgb(0 0 0 / .1);
  --prism-shadow-theme-swap-xs: 0px 2px 5px 0px rgb(0 0 0 / .2), 0px 0.4px 0.81px 0px rgb(0 0 0 / .1);
  --prism-color-theme-window-film-accent-primary: rgba(var(--prism-color-global-ignition-green-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-window-film-disabled: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-50));
  --prism-color-theme-window-film-focused: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-30));
  --prism-color-theme-window-film-pressed: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-window-film-hover: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-window-film-pop: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-brand-pink: var(--prism-color-global-pink);
  --prism-color-theme-brand-purple: var(--prism-color-global-purple);
  --prism-color-theme-brand-cyan: var(--prism-color-global-cyan);
  --prism-color-theme-brand-blue: var(--prism-color-global-blue-s-50);
  --prism-color-theme-brand-green: var(--prism-color-global-green-s-10);
  --prism-color-theme-brand-yellow: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-brand-orange: var(--prism-color-global-orange);
  --prism-color-theme-brand-red: var(--prism-color-global-red-s-50);
  --prism-color-theme-brand-ignition-green: var(--prism-color-global-ignition-green);
  --prism-color-theme-state-offline: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-state-charging-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-state-charging: var(--prism-color-global-orange-s-10);
  --prism-color-theme-state-muted-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-state-muted: var(--prism-color-global-red);
  --prism-color-theme-state-bad-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-state-bad: var(--prism-color-global-red);
  --prism-color-theme-state-ok-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-state-ok: var(--prism-color-global-yellow);
  --prism-color-theme-state-good-active-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-state-good-active: var(--prism-color-global-green);
  --prism-color-theme-disabled-contrast: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-40));
  --prism-color-theme-disabled: var(--prism-color-global-gray-s-30);
  --prism-color-theme-accent-primary-contrast: var(--prism-color-global-black);
  --prism-color-theme-accent-primary: var(--prism-color-global-ignition-green);
  --prism-color-theme-surface-variant: var(--prism-color-global-gray-s-50);
  --prism-color-theme-surface: var(--prism-color-global-gray-s-40);
  --prism-color-theme-neutral-pop-highest: var(--prism-color-global-gray-t-60);
  --prism-color-theme-neutral-pop-higher: var(--prism-color-global-gray-t-30);
  --prism-color-theme-neutral-pop-high: var(--prism-color-global-gray);
  --prism-color-theme-neutral-pop: var(--prism-color-global-gray-s-20);
  --prism-color-theme-neutral: var(--prism-color-global-gray-s-40);
  --prism-color-theme-neutral-dim: var(--prism-color-global-gray-s-50);
  --prism-color-theme-spacer: var(--prism-color-global-gray-t-40);
  --prism-color-theme-outline: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-ink: var(--prism-color-global-white);
  --prism-color-theme-base-contrast: var(--prism-color-global-white);
  --prism-color-theme-base-variant: var(--prism-color-global-gray-s-60);
  --prism-color-theme-base: var(--prism-color-global-black);
  --prism-color-theme-swap-window-film-accent-primary: rgba(var(--prism-color-global-blue-s-50-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-swap-window-film-disabled: rgba(var(--prism-color-global-white-rgb), var(--prism-opacity-global-50));
  --prism-color-theme-swap-window-film-focused: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-24));
  --prism-color-theme-swap-window-film-pressed: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-window-film-hover: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-6));
  --prism-color-theme-swap-window-film-pop: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-6));
  --prism-color-theme-swap-brand-pink: var(--prism-color-global-pink);
  --prism-color-theme-swap-brand-purple: var(--prism-color-global-purple);
  --prism-color-theme-swap-brand-cyan: var(--prism-color-global-cyan);
  --prism-color-theme-swap-brand-blue: var(--prism-color-global-blue-s-50);
  --prism-color-theme-swap-brand-green: var(--prism-color-global-green-s-10);
  --prism-color-theme-swap-brand-yellow: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-swap-brand-orange: var(--prism-color-global-orange);
  --prism-color-theme-swap-brand-red: var(--prism-color-global-red-s-50);
  --prism-color-theme-swap-brand-ignition-green: var(--prism-color-global-ignition-green);
  --prism-color-theme-swap-state-offline: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-state-charging-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-state-charging: var(--prism-color-global-orange);
  --prism-color-theme-swap-state-muted-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-swap-state-muted: var(--prism-color-global-red);
  --prism-color-theme-swap-state-bad-contrast: var(--prism-color-global-gray-s-70);
  --prism-color-theme-swap-state-bad: var(--prism-color-global-red-s-10);
  --prism-color-theme-swap-state-ok-contrast: var(--prism-color-global-gray-s-60);
  --prism-color-theme-swap-state-ok: var(--prism-color-global-yellow-s-10);
  --prism-color-theme-swap-state-good-active-contrast: var(--prism-color-global-white);
  --prism-color-theme-swap-state-good-active: var(--prism-color-global-green-s-10);
  --prism-color-theme-swap-disabled-contrast: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-40));
  --prism-color-theme-swap-disabled: var(--prism-color-global-gray-s-20);
  --prism-color-theme-swap-accent-primary-contrast: var(--prism-color-global-white);
  --prism-color-theme-swap-accent-primary: var(--prism-color-global-blue-s-50);
  --prism-color-theme-swap-surface-variant: var(--prism-color-global-white);
  --prism-color-theme-swap-surface: var(--prism-color-global-gray-t-60);
  --prism-color-theme-swap-neutral-pop-highest: var(--prism-color-global-gray-s-50);
  --prism-color-theme-swap-neutral-pop-higher: var(--prism-color-global-gray-s-20);
  --prism-color-theme-swap-neutral-pop-high: var(--prism-color-global-gray);
  --prism-color-theme-swap-neutral-pop: var(--prism-color-global-gray-t-30);
  --prism-color-theme-swap-neutral: var(--prism-color-global-gray-t-40);
  --prism-color-theme-swap-neutral-dim: var(--prism-color-global-gray-t-60);
  --prism-color-theme-swap-spacer: var(--prism-color-global-gray-s-40);
  --prism-color-theme-swap-outline: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-12));
  --prism-color-theme-swap-ink: rgba(var(--prism-color-global-black-rgb), var(--prism-opacity-global-87));
  --prism-color-theme-swap-base-contrast: var(--prism-color-global-black);
  --prism-color-theme-swap-base-variant: var(--prism-color-global-gray-t-40);
  --prism-color-theme-swap-base: var(--prism-color-global-white);
}

/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 3.0.0.128
 */

:where(:root),
[theme-spacing="normal"] {
  --prism-spacing-theme-xs: var(--prism-spacing-global-step-1);
  --prism-spacing-theme-small: var(--prism-spacing-global-step-2);
  --prism-spacing-theme-medium: var(--prism-spacing-global-step-4);
  --prism-spacing-theme-xm: var(--prism-spacing-global-step-5);
  --prism-spacing-theme-2xm: var(--prism-spacing-global-step-6);
  --prism-spacing-theme-large: var(--prism-spacing-global-step-8);
  --prism-spacing-theme-xl: var(--prism-spacing-global-step-11);
  --prism-spacing-theme-2xl: var(--prism-spacing-global-step-13);
  --prism-spacing-theme-xs-rem: var(--prism-spacing-global-step-1-rem);
  --prism-spacing-theme-small-rem: var(--prism-spacing-global-step-2-rem);
  --prism-spacing-theme-medium-rem: var(--prism-spacing-global-step-4-rem);
  --prism-spacing-theme-xm-rem: var(--prism-spacing-global-step-5-rem);
  --prism-spacing-theme-2xm-rem: var(--prism-spacing-global-step-6-rem);
  --prism-spacing-theme-large-rem: var(--prism-spacing-global-step-8-rem);
  --prism-spacing-theme-xl-rem: var(--prism-spacing-global-step-11-rem);
  --prism-spacing-theme-2xl-rem: var(--prism-spacing-global-step-13-rem);
  --prism-spacing-theme-xs-small: var(--prism-spacing-global-step-1-step-2);
  --prism-spacing-theme-xs-small-rem: var(--prism-spacing-global-step-1-step-2-rem);
  --prism-spacing-theme-xs-medium: var(--prism-spacing-global-step-1-step-4);
  --prism-spacing-theme-xs-medium-rem: var(--prism-spacing-global-step-1-step-4-rem);
  --prism-spacing-theme-xs-xm: var(--prism-spacing-global-step-1-step-5);
  --prism-spacing-theme-xs-xm-rem: var(--prism-spacing-global-step-1-step-5-rem);
  --prism-spacing-theme-xs-2xm: var(--prism-spacing-global-step-1-step-6);
  --prism-spacing-theme-xs-2xm-rem: var(--prism-spacing-global-step-1-step-6-rem);
  --prism-spacing-theme-xs-large: var(--prism-spacing-global-step-1-step-8);
  --prism-spacing-theme-xs-large-rem: var(--prism-spacing-global-step-1-step-8-rem);
  --prism-spacing-theme-xs-xl: var(--prism-spacing-global-step-1-step-11);
  --prism-spacing-theme-xs-xl-rem: var(--prism-spacing-global-step-1-step-11-rem);
  --prism-spacing-theme-xs-2xl: var(--prism-spacing-global-step-1-step-13);
  --prism-spacing-theme-xs-2xl-rem: var(--prism-spacing-global-step-1-step-13-rem);
  --prism-spacing-theme-small-medium: var(--prism-spacing-global-step-2-step-4);
  --prism-spacing-theme-small-medium-rem: var(--prism-spacing-global-step-2-step-4-rem);
  --prism-spacing-theme-small-xm: var(--prism-spacing-global-step-2-step-5);
  --prism-spacing-theme-small-xm-rem: var(--prism-spacing-global-step-2-step-5-rem);
  --prism-spacing-theme-small-2xm: var(--prism-spacing-global-step-2-step-6);
  --prism-spacing-theme-small-2xm-rem: var(--prism-spacing-global-step-2-step-6-rem);
  --prism-spacing-theme-small-large: var(--prism-spacing-global-step-2-step-8);
  --prism-spacing-theme-small-large-rem: var(--prism-spacing-global-step-2-step-8-rem);
  --prism-spacing-theme-small-xl: var(--prism-spacing-global-step-2-step-11);
  --prism-spacing-theme-small-xl-rem: var(--prism-spacing-global-step-2-step-11-rem);
  --prism-spacing-theme-small-2xl: var(--prism-spacing-global-step-2-step-13);
  --prism-spacing-theme-small-2xl-rem: var(--prism-spacing-global-step-2-step-13-rem);
  --prism-spacing-theme-medium-xm: var(--prism-spacing-global-step-4-step-5);
  --prism-spacing-theme-medium-xm-rem: var(--prism-spacing-global-step-4-step-5-rem);
  --prism-spacing-theme-medium-2xm: var(--prism-spacing-global-step-4-step-6);
  --prism-spacing-theme-medium-2xm-rem: var(--prism-spacing-global-step-4-step-6-rem);
  --prism-spacing-theme-medium-large: var(--prism-spacing-global-step-4-step-8);
  --prism-spacing-theme-medium-large-rem: var(--prism-spacing-global-step-4-step-8-rem);
  --prism-spacing-theme-medium-xl: var(--prism-spacing-global-step-4-step-11);
  --prism-spacing-theme-medium-xl-rem: var(--prism-spacing-global-step-4-step-11-rem);
  --prism-spacing-theme-medium-2xl: var(--prism-spacing-global-step-4-step-13);
  --prism-spacing-theme-medium-2xl-rem: var(--prism-spacing-global-step-4-step-13-rem);
  --prism-spacing-theme-xm-2xm: var(--prism-spacing-global-step-5-step-6);
  --prism-spacing-theme-xm-2xm-rem: var(--prism-spacing-global-step-5-step-6-rem);
  --prism-spacing-theme-xm-large: var(--prism-spacing-global-step-5-step-8);
  --prism-spacing-theme-xm-large-rem: var(--prism-spacing-global-step-5-step-8-rem);
  --prism-spacing-theme-xm-xl: var(--prism-spacing-global-step-5-step-11);
  --prism-spacing-theme-xm-xl-rem: var(--prism-spacing-global-step-5-step-11-rem);
  --prism-spacing-theme-xm-2xl: var(--prism-spacing-global-step-5-step-13);
  --prism-spacing-theme-xm-2xl-rem: var(--prism-spacing-global-step-5-step-13-rem);
  --prism-spacing-theme-2xm-large: var(--prism-spacing-global-step-6-step-8);
  --prism-spacing-theme-2xm-large-rem: var(--prism-spacing-global-step-6-step-8-rem);
  --prism-spacing-theme-2xm-xl: var(--prism-spacing-global-step-6-step-11);
  --prism-spacing-theme-2xm-xl-rem: var(--prism-spacing-global-step-6-step-11-rem);
  --prism-spacing-theme-2xm-2xl: var(--prism-spacing-global-step-6-step-13);
  --prism-spacing-theme-2xm-2xl-rem: var(--prism-spacing-global-step-6-step-13-rem);
  --prism-spacing-theme-large-xl: var(--prism-spacing-global-step-8-step-11);
  --prism-spacing-theme-large-xl-rem: var(--prism-spacing-global-step-8-step-11-rem);
  --prism-spacing-theme-large-2xl: var(--prism-spacing-global-step-8-step-13);
  --prism-spacing-theme-large-2xl-rem: var(--prism-spacing-global-step-8-step-13-rem);
  --prism-spacing-theme-xl-2xl: var(--prism-spacing-global-step-11-step-13);
  --prism-spacing-theme-xl-2xl-rem: var(--prism-spacing-global-step-11-step-13-rem);
}

@media (any-pointer: fine) {
[theme-spacing="dense"] {
  --prism-spacing-theme-xs: var(--prism-spacing-global-step-1);
  --prism-spacing-theme-small: var(--prism-spacing-global-step-2);
  --prism-spacing-theme-medium: var(--prism-spacing-global-step-3);
  --prism-spacing-theme-xm: var(--prism-spacing-global-step-4);
  --prism-spacing-theme-2xm: var(--prism-spacing-global-step-5);
  --prism-spacing-theme-large: var(--prism-spacing-global-step-6);
  --prism-spacing-theme-xl: var(--prism-spacing-global-step-8);
  --prism-spacing-theme-2xl: var(--prism-spacing-global-step-10);
  --prism-spacing-theme-xs-rem: var(--prism-spacing-global-step-1-rem);
  --prism-spacing-theme-small-rem: var(--prism-spacing-global-step-2-rem);
  --prism-spacing-theme-medium-rem: var(--prism-spacing-global-step-3-rem);
  --prism-spacing-theme-xm-rem: var(--prism-spacing-global-step-4-rem);
  --prism-spacing-theme-2xm-rem: var(--prism-spacing-global-step-5-rem);
  --prism-spacing-theme-large-rem: var(--prism-spacing-global-step-6-rem);
  --prism-spacing-theme-xl-rem: var(--prism-spacing-global-step-8-rem);
  --prism-spacing-theme-2xl-rem: var(--prism-spacing-global-step-10-rem);
  --prism-spacing-theme-xs-small: var(--prism-spacing-global-step-1-step-2);
  --prism-spacing-theme-xs-small-rem: var(--prism-spacing-global-step-1-step-2-rem);
  --prism-spacing-theme-xs-medium: var(--prism-spacing-global-step-1-step-3);
  --prism-spacing-theme-xs-medium-rem: var(--prism-spacing-global-step-1-step-3-rem);
  --prism-spacing-theme-xs-xm: var(--prism-spacing-global-step-1-step-4);
  --prism-spacing-theme-xs-xm-rem: var(--prism-spacing-global-step-1-step-4-rem);
  --prism-spacing-theme-xs-2xm: var(--prism-spacing-global-step-1-step-5);
  --prism-spacing-theme-xs-2xm-rem: var(--prism-spacing-global-step-1-step-5-rem);
  --prism-spacing-theme-xs-large: var(--prism-spacing-global-step-1-step-6);
  --prism-spacing-theme-xs-large-rem: var(--prism-spacing-global-step-1-step-6-rem);
  --prism-spacing-theme-xs-xl: var(--prism-spacing-global-step-1-step-8);
  --prism-spacing-theme-xs-xl-rem: var(--prism-spacing-global-step-1-step-8-rem);
  --prism-spacing-theme-xs-2xl: var(--prism-spacing-global-step-1-step-10);
  --prism-spacing-theme-xs-2xl-rem: var(--prism-spacing-global-step-1-step-10-rem);
  --prism-spacing-theme-small-medium: var(--prism-spacing-global-step-2-step-3);
  --prism-spacing-theme-small-medium-rem: var(--prism-spacing-global-step-2-step-3-rem);
  --prism-spacing-theme-small-xm: var(--prism-spacing-global-step-2-step-4);
  --prism-spacing-theme-small-xm-rem: var(--prism-spacing-global-step-2-step-4-rem);
  --prism-spacing-theme-small-2xm: var(--prism-spacing-global-step-2-step-5);
  --prism-spacing-theme-small-2xm-rem: var(--prism-spacing-global-step-2-step-5-rem);
  --prism-spacing-theme-small-large: var(--prism-spacing-global-step-2-step-6);
  --prism-spacing-theme-small-large-rem: var(--prism-spacing-global-step-2-step-6-rem);
  --prism-spacing-theme-small-xl: var(--prism-spacing-global-step-2-step-8);
  --prism-spacing-theme-small-xl-rem: var(--prism-spacing-global-step-2-step-8-rem);
  --prism-spacing-theme-small-2xl: var(--prism-spacing-global-step-2-step-10);
  --prism-spacing-theme-small-2xl-rem: var(--prism-spacing-global-step-2-step-10-rem);
  --prism-spacing-theme-medium-xm: var(--prism-spacing-global-step-3-step-4);
  --prism-spacing-theme-medium-xm-rem: var(--prism-spacing-global-step-3-step-4-rem);
  --prism-spacing-theme-medium-2xm: var(--prism-spacing-global-step-3-step-5);
  --prism-spacing-theme-medium-2xm-rem: var(--prism-spacing-global-step-3-step-5-rem);
  --prism-spacing-theme-medium-large: var(--prism-spacing-global-step-3-step-6);
  --prism-spacing-theme-medium-large-rem: var(--prism-spacing-global-step-3-step-6-rem);
  --prism-spacing-theme-medium-xl: var(--prism-spacing-global-step-3-step-8);
  --prism-spacing-theme-medium-xl-rem: var(--prism-spacing-global-step-3-step-8-rem);
  --prism-spacing-theme-medium-2xl: var(--prism-spacing-global-step-3-step-10);
  --prism-spacing-theme-medium-2xl-rem: var(--prism-spacing-global-step-3-step-10-rem);
  --prism-spacing-theme-xm-2xm: var(--prism-spacing-global-step-4-step-5);
  --prism-spacing-theme-xm-2xm-rem: var(--prism-spacing-global-step-4-step-5-rem);
  --prism-spacing-theme-xm-large: var(--prism-spacing-global-step-4-step-6);
  --prism-spacing-theme-xm-large-rem: var(--prism-spacing-global-step-4-step-6-rem);
  --prism-spacing-theme-xm-xl: var(--prism-spacing-global-step-4-step-8);
  --prism-spacing-theme-xm-xl-rem: var(--prism-spacing-global-step-4-step-8-rem);
  --prism-spacing-theme-xm-2xl: var(--prism-spacing-global-step-4-step-10);
  --prism-spacing-theme-xm-2xl-rem: var(--prism-spacing-global-step-4-step-10-rem);
  --prism-spacing-theme-2xm-large: var(--prism-spacing-global-step-5-step-6);
  --prism-spacing-theme-2xm-large-rem: var(--prism-spacing-global-step-5-step-6-rem);
  --prism-spacing-theme-2xm-xl: var(--prism-spacing-global-step-5-step-8);
  --prism-spacing-theme-2xm-xl-rem: var(--prism-spacing-global-step-5-step-8-rem);
  --prism-spacing-theme-2xm-2xl: var(--prism-spacing-global-step-5-step-10);
  --prism-spacing-theme-2xm-2xl-rem: var(--prism-spacing-global-step-5-step-10-rem);
  --prism-spacing-theme-large-xl: var(--prism-spacing-global-step-6-step-8);
  --prism-spacing-theme-large-xl-rem: var(--prism-spacing-global-step-6-step-8-rem);
  --prism-spacing-theme-large-2xl: var(--prism-spacing-global-step-6-step-10);
  --prism-spacing-theme-large-2xl-rem: var(--prism-spacing-global-step-6-step-10-rem);
  --prism-spacing-theme-xl-2xl: var(--prism-spacing-global-step-8-step-10);
  --prism-spacing-theme-xl-2xl-rem: var(--prism-spacing-global-step-8-step-10-rem);
}
}


.prism-link {
	text-decoration: none;
	outline: none;
	color: var(--prism-color-theme-base-contrast);

	--border: var(--prism-spacing-theme-xs);
	--padding-inline: var(--prism-spacing-theme-xs);
	--current-border: 0px;
	--current-padding: 0px;

	/* to account for border and padding, subtract it out with negative margin */
	margin-inline: calc(-1 * (var(--current-border) + var(--current-padding)));
	border-block-end: var(--border) solid var(--prism-color-theme-accent-primary);
	border-block-start: var(--border) solid transparent;
	padding-inline: var(--current-padding);
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

.prism-link:is(:hover, :focus-visible, :active) {
	--current-border: var(--border);
	--current-padding: var(--padding-inline);

	border: var(--border) solid var(--prism-color-theme-accent-primary);
}

.prism-link:is(:hover, :active) {
	background-color: var(--prism-color-theme-accent-primary);
	color: var(--prism-color-theme-accent-primary-contrast);
}

.prism-link:active {
	/* use a border image to overlay a window film over the border and background color. note that it doesn't overlay the content, but I think that's ok. */
	border-image: linear-gradient(
			var(--prism-color-theme-window-film-pressed),
			var(--prism-color-theme-window-film-pressed)
		)
		fill 0 / 0;
}

.pwc-overlay {
	position: absolute;
	z-index: 10000;
}

.prism-divider {
	margin: 0;
	border: 0px solid var(--prism-color-theme-outline);
	border-radius: var(--prism-border-radius-global-pill);

	&:not([orientation="vertical"]) {
		border-bottom-width: 1px;
	}

	&[orientation="vertical"] {
		border-right-width: 1px;
	}
}
